<template>
    <div>
        <HeroContentDetail v-if="data" :data="data" heroType="blog" heroSize="small"></HeroContentDetail>
        <section class="section main-content" v-if="data">
            <div class="container">
                <div class="column-wrapper fullwidth" v-if="data.templateContentFields">
                    <div class="column content">
                        <div class="content-wrapper" v-html="data.templateContentFields.contentFirst"></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section main-content" v-if="data">
            <div class="container">
                <div class="column-wrapper fullwidth" v-if="data.templateContentFields">
                    <div class="column content">
                        <div class="content-wrapper" v-html="data.templateContentFields.contentSecond"></div>
                        <!-- <ul class="tags">
						<li v-for="tag in postData.tags" :key="tag">{{ tag }}</li>
					</ul> -->
                        <!-- <ul class="tags">
                            <li v-if="data.fields.blogcategories">
                                {{ getEnumVal(data.fields.blogcategories) }}
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </section>

        <PlayList v-if="data && data.relatedPlayList && data.relatedPlayList !== ''" :playlistId="data.relatedPlayList" />
    </div>
</template>

<script>
import {
    //fetchCmsMedia,
    fetchTemplateContentDetailsBySlug,
} from "@streampac.io/chef_sp_1";

import HeroContentDetail from "@/components/HeroContentDetail";

import PlayList from "@/components/PlayList";

export default {
    name: "BlogSingle",
    inject: ["isAuthenticated"],
    data() {
        return {
            paging: {
                pageNumber: 1,
                pageSize: 6,
            },
            data: null,
        };
    },
    components: {
        HeroContentDetail,
        PlayList,
    },
    created() {
        this.getPageContent();
    },
    methods: {
        getPageContent() {
            if (this.$route.params.slug !== "") {
                fetchTemplateContentDetailsBySlug(
                    this.isAuthenticated,
                    this.$route.params.slug,
                    "?flattenFields=true"
                ).then((response) => {
                    this.data = response;

                    // if (
                    // 	response.templateContentFields.mediaFirstImage &&
                    // 	response.templateContentFields.mediaFirstImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaFirstImage,
                    // 		"mediaFirstImage"
                    // 	);
                    // }

                    // if (
                    // 	response.templateContentFields.mediaSecondImage &&
                    // 	response.templateContentFields.mediaSecondImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaSecondImage,
                    // 		"mediaSecondImage"
                    // 	);
                    // }
                });
            }
        },
        stripHtml(val) {
            const strippedString = val.replace(/(<([^>]+)>)/gi, "");

            return strippedString;
        },
        // resolveCmsMedia(val, name) {
        // 	if (val !== "") {
        // 		fetchCmsMedia(this.isAuthenticated, val).then((response) => {
        // 			this[name] = response;
        // 		});
        // 	}
        // },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    if (enumFilter && enumFilter.length > 0) {
                        return enumFilter[0].enumValue;
                    }
                } else {
                    return "";
                }
            }

            return "";
        },
        hasHistory() {
            return window.history.length > 2;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.blogsingle .section.main-content .content-wrapper:first-child p:first-child {
    @include font-size(20);
    line-height: 160%;
}
</style>
